import { GOERLI } from '../types';
export default {
  type: GOERLI,
  service: 'myetherwallet.com-ws',
  url: 'wss://nodes.mewapi.io/ws/goerli',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
