import XDC from '../types/XDC';
export default {
  type: XDC,
  service: 'xdc-network-ws',
  url: 'wss://mewws.blocksscan.io/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
