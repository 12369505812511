var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "walletBg" },
    [
      _c("router-view"),
      _c("module-toast"),
      _c("module-global-modals"),
      _c("module-analytics"),
      _c("module-buy-sell", {
        attrs: { open: _vm.buySellOpen },
        on: {
          close: function ($event) {
            _vm.buySellOpen = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }