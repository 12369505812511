/* eslint camelcase: 0 */
import en_US from './en_US';
import ru_RU from './ru_RU';

const languages = {
  en_US: en_US,
  ru_RU: ru_RU
};

export default languages;
