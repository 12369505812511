import XDC from '../types/tXDC';
export default {
  type: XDC,
  service: 'apothem-network-ws',
  url: 'wss://mewarpc.xdcrpc.com/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
